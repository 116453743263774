<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle  == '添加参数' ? $t('系统管理.参数设置.添加参数') : $t('系统管理.参数设置.修改参数')}}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('系统管理.参数设置.参数名称')" prop="configName">
        <a-input v-model="form.configName" :placeholder="$t('系统管理.参数设置.请输入参数名称')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('系统管理.参数设置.参数分组')" prop="configGroup">
        <a-input v-model="form.configGroup" :placeholder="$t('系统管理.参数设置.请输入参数分组')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('系统管理.参数设置.参数键名')" prop="configKey">
        <a-input v-model="form.configKey" :placeholder="$t('系统管理.参数设置.请输入参数键名')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('系统管理.参数设置.参数类型')" prop="configType">
        <a-radio-group v-model="form.configType" button-style="solid">
          <a-radio-button v-for="(d, index) in  configTypeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('系统管理.参数设置.参数键值')" prop="configValue">
        <a-input v-model="form.configValue" :placeholder="$t('系统管理.参数设置.请输入参数键值')" v-if="form.configType=== '1' " />
        <a-textarea v-model="form.configValue" :placeholder="$t('系统管理.参数设置.请输入参数键值')" v-if="form.configType=== '2' " />
        <editor v-model="form.configValue" v-if="form.configType=== '3' " />
      </a-form-model-item>

      <a-form-model-item :label="$t('系统管理.参数设置.系统内置')" prop="configSystem">
        <a-radio-group v-model="form.configSystem" button-style="solid">
          <a-radio-button v-for="(d, index) in typeOptions" :key="index" :value="d.value">
            {{ d.label }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :label="$t('系统管理.参数设置.备注')" prop="remark">
        <a-input v-model="form.remark" :placeholder="$t('系统管理.参数设置.请输入备注')" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getConfig, addConfig, updateConfig } from '@/api/system/config'
import Editor from '@/components/Editor'
export default {
  name: 'CreateForm',
  props: {
    typeOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      configTypeOptions: [{"value":"1","label":"输入框"},{"value":"2","label":"文本框"},{"value":"3","label":"富文本"}],
      // 表单参数
      form: {
        id: undefined,
        configName: undefined,
        configGroup: undefined,
        configKey: undefined,
        configValue: "",
        configSystem: 'Y',
        configType: "1",
        remark: undefined
      },
      open: false,
      rules: {
        configName: [{ required: true, message: '参数名称不能为空', trigger: 'blur' }],
        configGroup: [{ required: true, message: '参数名称不能为空', trigger: 'blur' }],
        configKey: [{ required: true, message: '参数键名不能为空', trigger: 'blur' }],
        configValue: [{ required: true, message: '参数键值不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: undefined,
        configName: undefined,
        configGroup: undefined,
        configKey: undefined,
        configValue: '',
        configSystem: 'Y',
        configType: "1",
        remark: undefined
      }
    },
     /** 新增按钮操作 */
    handleAdd () {
      this.reset()
      this.open = true
      this.formTitle = '添加参数'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      const configId = row ? row.id : ids
      getConfig(configId).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改参数'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {
            updateConfig(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addConfig(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
